/// <reference path="../../node_modules/@types/jquery/index.d.ts" /> 

declare const $zopim: any;
declare const YT: any;

$(document).ready(() => {

    let zopimLoaded = false;

    const $chatButton = $('.chat-button');
    $chatButton.on('click', () => {
        if (!zopimLoaded) {
            const el = document.createElement('script');
            el.src = '/dist/js/snapengage.js';
            el.async = false;
            document.body.appendChild(el);

            el.addEventListener('load', () => {
                setTimeout(() => {
                    $zopim.livechat.window.show();
                }, 1000)
            });
        } else {
            $zopim.livechat.window.show();
        }
    });

    const $goButton = $('#buttonGo')
    $goButton.on('click', () => {
        var target = document.getElementById('stateSelectDropdown') as HTMLSelectElement;;
        if (target) {
            let selectedOption = target.options[target.selectedIndex];
            let formattedStateName = selectedOption.text
                .replace(' ', "-")
                .replace(',', "")
                .replace('.', "");

            if (formattedStateName[formattedStateName.length-1] === ".")
                formattedStateName = formattedStateName.slice(0,-1);
            
            window.location.href = "https://www.gototrafficschool.com/states/" + formattedStateName;
        } else {
            alert("If you received a traffic ticket in a state other than Texas \nPlease choose the state from the drop-down list to continue");
        }
    });

    const $btnYoutubePlayer = $("#play_vid");
    $btnYoutubePlayer.on('click', () => {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        tag.async = false;
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        tag.addEventListener('load', () => {
            setTimeout(() => {
                onYouTubeIframeAPIReady();
            }, 1000)
        });
    });
    
    var player;
    function onYouTubeIframeAPIReady() {
        player = new YT.Player('player', {
            height: '250',
            width: '100%',
            videoId: 'mH0yRzoG3ck',
            events: {
                'onReady': onPlayerReady,
            }
        });
    }

    function onPlayerReady(event) {
        $("#play_vid, .container_image").addClass('hidden');
        $(".video-player").removeClass('hidden');
        event.target.playVideo();
    }

    const WebFontConfig = {
        google: { families: ['Lato:400,300,700', 'Handlee'] }
    };
    (function () {
        var wf = document.createElement('script');
        wf.src = ('https:' == document.location.protocol ? 'https' : 'http') +
            '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        wf.type = 'text/javascript';
        wf.async = true;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wf, s);
    })();
});